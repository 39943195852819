import { __decorate } from "tslib";
import { Component, Vue, Prop, Ref, Watch, } from 'vue-property-decorator';
import { sexs, OperateType, NodeType } from '../../../../typings/organization/org-manage';
import * as Utils from '@/common/utils';
let UserForm = class UserForm extends Vue {
    constructor() {
        super(...arguments);
        this.sexs = sexs;
        this.NodeType = NodeType;
        this.OperateType = OperateType;
        this.isUploaded = false;
    }
    get uploadUrl() {
        return '/api/file/upload';
    }
    get token() {
        return localStorage.getItem('token');
    }
    onFormChange() {
        if (this.isEdit) {
            Utils.trim(this.form);
        }
    }
    beforeDestroy() {
        this.cform.clearValidate();
        this.cform.resetFields();
        this.form.baseUserInfo.imgUrl = '';
    }
    /**
     * 上传头像
     */
    async updoadAvatorChange(info) {
        if (info.file.status === 'done') {
            if (info.file.response.success) {
                this.form.baseUserInfo.imgUrl = '/api/file/download?refId=' + info.file.response.data.refId;
            }
        }
        if (info.file.status === 'error' && !this.isUploaded) {
            this.$message.error(info.file.error.message);
            this.isUploaded = true;
        }
    }
    /**
     * 获取上传的应用图标并判断是否符合限制
     */
    beforeUpload(file) {
        const isPic = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
        const isLimitSize = file.size / 1024 < 100;
        if (!isPic) {
            this.$message.error('请上传JPEG、PNG、JPG格式的图片');
            return false;
        }
        if (!isLimitSize) {
            this.$message.error('图片大小不能超过100K');
            return false;
        }
        this.isUploaded = false;
        return true;
    }
};
__decorate([
    Prop()
], UserForm.prototype, "form", void 0);
__decorate([
    Prop()
], UserForm.prototype, "rules", void 0);
__decorate([
    Prop({ default: false })
], UserForm.prototype, "isAdd", void 0);
__decorate([
    Prop({ default: false })
], UserForm.prototype, "isEdit", void 0);
__decorate([
    Prop({ default: OperateType.Add })
], UserForm.prototype, "operateType", void 0);
__decorate([
    Ref()
], UserForm.prototype, "cform", void 0);
__decorate([
    Watch('form', { deep: true })
], UserForm.prototype, "onFormChange", null);
UserForm = __decorate([
    Component({
        name: 'UserForm',
        components: {
            CForm: () => import('@/components/shared/CForm/Form'),
            CFormItem: () => import('@/components/shared/CForm/FormItem'),
            OrgInfo: () => import('./org-info.vue'),
            Avatar: () => import('../avatar.vue'),
        },
    })
], UserForm);
export default UserForm;
